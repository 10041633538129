import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DateTime } from 'luxon';
import { BehaviorSubject, EMPTY, Observable, map } from 'rxjs';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NewSession } from 'app/models/sessions/session';
import { ClientService } from 'app/services/client.service';
import { OBJECTSSESSION, ObjectSession } from 'app/services/objects-session';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'app/core/material/material.module';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { NumberToTimePipe } from '../../../edit-work-hours-timeline/pipes/seconds-to-time.pipe';

export interface Data {
  session: NewSession,
  type: 'add' | 'edit'
}

@Component({
  selector: 'tm-session-add',
  standalone: true,
  imports: [CommonModule, MaterialModule, NgxMatNativeDateModule, NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    NgxMatTimepickerModule, ReactiveFormsModule, FormsModule, NumberToTimePipe],
  templateUrl: './session-add.component.html',
  styleUrls: ['./session-add.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [],
})
export class SessionAddComponent {
  readonly submitting$ = new BehaviorSubject<boolean>(false);
  readonly sessionForm: FormGroup = this.fb.group({
    object: [null, Validators.required],
    start: [new Date(), Validators.required],
    end: [new Date(), Validators.required],
  });


  get sessionStart() {
    return this.sessionForm.get('start').value;
  }

  get sessionEnd() {
    return this.sessionForm.get('end').value;
  }

  get objectId() {
    return this.sessionForm.get('object').value.id;
  }

  get objectKind() {
    return this.sessionForm.get('object').value.objectKind;
  }

  get duration() {
    return DateTime.fromJSDate(this.sessionEnd).diff(DateTime.fromJSDate(this.sessionStart), 'seconds').seconds;
  }

  get sessionFormInvalid() {
    return this.sessionForm.invalid;
  }

  get maxStartTime() {
    return DateTime.fromJSDate(this.sessionEnd).minus({ minute: 1 }).toJSDate();
  }

  get minEndTime() {
    return DateTime.fromJSDate(this.sessionStart).plus({ minute: 1 }).toJSDate();
  }

  readonly objects$ = this.objectsSession$.pipe(
    map(objects => objects.reduce((acc, curr) => {
      if (acc[curr.category]) {
        acc[curr.category].push(curr);
      } else acc[curr.category] = [curr];
      return acc;
    }, {})),
    map((objects: { [key: string]: Array<ObjectSession> }) => {
      let data = {};
      for (const [key, value] of Object.entries(objects)) {
        data[`${key}`] = value.sort((v1, v2) => v1.title.localeCompare(v2.title))
      }
      return data;
    })
  );


  constructor(
    @Inject(OBJECTSSESSION) private readonly objectsSession$: Observable<Array<ObjectSession>>,
    private readonly dialogRef: MatDialogRef<SessionAddComponent>,
    private readonly fb: FormBuilder,
    private readonly clientService: ClientService,
    @Inject(MAT_DIALOG_DATA) public readonly data: Data) {
    this.sessionForm.patchValue({
      object: (data.session.objectId && data.session.objectKind) ? { id: data.session.objectId, objectKind: data.session.objectKind } : null,
      start: new Date(data.session.startTime),
      end: new Date(data.session.endTime),
    });
  }

  compare(o1: ObjectSession, o2: ObjectSession) {
    return o1?.id === o2?.id;
  }

  private _submit(session: NewSession) {
    switch (this.data.type) {
      case 'add':
        return this.clientService.addSession(session);
      case 'edit':
        return this.clientService.changeSession(this.data.session.id, session);
      default:
        return EMPTY;
    }
  }

  submit(): void {
    this.submitting$.next(true);
    const session = {
      startTime: this.sessionStart.toJSON(),
      endTime: this.sessionEnd.toJSON(),
      objectId: this.objectId,
      objectKind: this.objectKind
    };
    this._submit(session).subscribe({
      next: () => {
        this.dialogRef.close(true);
      },
      error: () => {
        this.submitting$.next(false);
      }
    });
  }
}