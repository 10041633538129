const BASEURL = 'https://spectrotime-dev.azure-api.net';
const VERSION = 'v1;rev=3';

export const environment = {
  production: false,
  auth0: {
    domain: 'timelake-dev.eu.auth0.com',
    clientId: 'angQuGFM4FsyDlLDllLRSlAiIxK1Z56f',
    audience: BASEURL,
    version: VERSION
  },
  apiConfig: {
    oldBaseUrl: 'https://dev-timeter-api.azurewebsites.net',
    baseUrl: `${BASEURL}/${VERSION}`,
    version: `${VERSION}`
  },
  hubConnection: 'https://spectrotime-client-service-dev.azurewebsites.net/hubs/web',
  b2cPolicies: {
    names: {
      signUpSignIn: "B2C_1A_SIGNUP_SIGNIN",
      signUp: "B2C_1A_SIGNUP",
      resetPassword: "B2C_1_PasswordReset",
    },
    authorities: {
      signUpSignIn: {
        authority: 'https://id-dev.spectrotime.com/a1b90253-a8a7-4cf6-8324-a82a1c0c6e0d/B2C_1A_SIGNUP_SIGNIN'
      },
      signUp: {
        authority: 'https://id-dev.spectrotime.com/a1b90253-a8a7-4cf6-8324-a82a1c0c6e0d/B2C_1A_SIGNUP'
      },
      resetPassword: {
        authority: 'https://id-dev.spectrotime.com/a1b90253-a8a7-4cf6-8324-a82a1c0c6e0d/B2C_1_PasswordReset'
      },
    },
    authorityDomain: "id-dev.spectrotime.com"
  }
};
