import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';


@Component({
  selector: 'tm-login-failed',
  templateUrl: './login-failed.component.html',
  styleUrls: ['./login-failed.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginFailedComponent {
  constructor(private readonly auth0Service: AuthService) { }

  logout() {
    this.auth0Service.logout();
  }
}