import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { filter, mergeMap, tap } from 'rxjs/operators';
import { AppInfoComponent } from './modules/app-service/components/app-info/app-info.component';
import { AuthService, GenericError } from '@auth0/auth0-angular';
import { DOCUMENT } from '@angular/common';
import { ClientService } from './services/client.service';
import { fromEvent } from 'rxjs';
import { IsNewVersionMsgAppComponent } from './shared/components/is-new-version-msg-app/is-new-version-msg-app.component';
import { UpdateAppService } from './services/update-app.service';

@Component({
  selector: 'tm-root',
  template: '<router-outlet></router-outlet>',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private auth0Service: AuthService,
    private snackbar: MatSnackBar,
    @Inject(DOCUMENT) private _document: Document,
    private readonly clientService: ClientService,
    private readonly updateAppService: UpdateAppService,
  ) { }

  ngOnInit(): void {
    this.updateAppService.isLoadNewVersion$.pipe(
      filter(isNewVersion => isNewVersion),
    ).subscribe(() => this.snackbar.openFromComponent(IsNewVersionMsgAppComponent));

    fromEvent(this._document, 'visibilitychange').pipe(
      filter(() => this._document.visibilityState === 'visible')
    ).subscribe(() => this.clientService.refreshState$.next());

    this.snackbar.openFromComponent(AppInfoComponent, {
      horizontalPosition: 'right',
      verticalPosition: 'bottom'
    });

    this.auth0Service.error$.pipe(
      tap((err) => console.error(err)),
      // filter((e) => e instanceof GenericError && e.error === 'login_required'),
      // mergeMap(() => this.auth0Service.loginWithRedirect())
    ).subscribe();

    this.auth0Service.user$.subscribe((u) => console.log(u));
  }
}