<div class="flex flex-col gap-4">
  <div class="flex items-center justify-between gap-4">
    <div class="text-2xl">Idle Time</div>
    <!-- <button mat-icon-button matDialogClose><mat-icon svgIcon="mat_solid:close"></mat-icon></button> -->
  </div>
  <div class="flex flex-col">
    <div class="font-semibold text-lg">
      Your last time commit was <span class="font-bold text-xl">{{duration$ | async}}</span> ago
    </div>
    <div class="text-lg font-semibold text-secondary mt-1">
      <div *ngIf="selectedObject?.project; else projectTmpl">
        <div>Project: {{selectedObject?.project?.name}}</div>
        <div>Task: {{selectedObject?.title}}</div>
      </div>
      <ng-template #projectTmpl>
        <div>Project: {{selectedObject?.title}}</div>
        <div>Task: -</div>
      </ng-template>
    </div>
    <mat-divider class="my-4"></mat-divider>
    <div class="flex flex-col text-lg">
      <div class="font-semibold">
        Were you working within this time interval?
      </div>
      <div class="font-bold text-xl">
        {{range$ | async}}
      </div>
      <mat-radio-group aria-label="Select an option" [color]="'primary'" (change)="isWorkingTimeChange($event)">
        <mat-radio-button [value]="false" checked>No (remove this time interval)</mat-radio-button>
        <mat-radio-button [value]="true">Yes (keep this time)</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <ng-container *ngIf="error$ | async as error">
    <div class="text-sm text-warn-500">{{error}} Please, try to later!</div>
  </ng-container>
  <div class="flex flex-col sm:flex-row gap-4 sm:items-center sm:justify-end">
    <button mat-stroked-button [color]="'primary'" (click)="stop()">
      <mat-icon svgIcon="mat_solid:stop"></mat-icon>
      Stop timer
    </button>
    <button mat-raised-button [color]="'primary'" (click)="continue()" [disabled]="isResume$ | async">
      <mat-icon svgIcon="mat_solid:play_arrow"></mat-icon>
      Resume timer
    </button>
  </div>
</div>