import { inject, Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, ReplaySubject, tap, map, take, switchMap, retry } from 'rxjs';
import { OrganizationsList } from 'app/models/organization/organizations-list';
import { Organization, OrganizationView } from 'app/models/organization/organization';
import { OrganizationCategoriesList, OrganizationCategory } from 'app/models/category/category';
import { User } from 'app/models/user/user';
import { Tickets } from 'app/models/ticket/tickets';
import { AuditLogs } from 'app/models/logs/audit-logs';
import { Plan, Plans } from 'app/models/organization/plan/plan';
import { WINDOW } from 'app/core/core.module';
import { environment } from 'environments/environment';
import { Clients } from 'app/modules/organization/modules/reports/pages/users-online/users-online.component';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  private readonly _organizations: ReplaySubject<Organization[]> = new ReplaySubject<Organization[]>(1);
  private _selectedOrganization: OrganizationView = null;
  private readonly _httpClient = inject(HttpClient);

  constructor(
    @Inject(WINDOW) private readonly window: Window,
    // private readonly organizationGuardRefreshService: OrganizationGuardRefreshService,
  ) { }

  set organizations(organizations: Organization[]) {
    this.setStoreOrganization(organizations);
    this._organizations.next(organizations);
  }

  get organizations$(): Observable<Organization[]> {
    return this._organizations.asObservable().pipe(
      take(1)
    );
  }

  private setStoreOrganization(organizations: Array<Organization>): void {
    const organizationId = localStorage.getItem('organizationSelected') || organizations[0]?.id;
    localStorage.setItem('organizationSelected', organizationId);
  }

  get organizationSelectedId(): string {
    return localStorage.getItem('organizationSelected') || this._selectedOrganization?.id || '';
  }

  get organizationSelected(): OrganizationView {
    return this._selectedOrganization;
  }

  getOrganizations(): Observable<Organization[]> {
    return this._httpClient.get<OrganizationsList>(`${environment.apiConfig.baseUrl}/organizations`).pipe(
      map((organizationsList) => organizationsList.list)
    );
  }

  getPlans(): Observable<Plan[]> {
    return this._httpClient.get<Plans>(`${environment.apiConfig.baseUrl}/organizations/${this.organizationSelectedId}/plans`).pipe(map((plans) => plans.list));
  }

  checkoutPlans(id: string): Observable<{ redirectUrl: string }> {
    return this._httpClient.post<{ redirectUrl: string }>(`${environment.apiConfig.baseUrl}/organizations/${this.organizationSelectedId}/plans/${id}/checkout`, { successUrl: `${this.window.origin}/settings/subsciptions-and-billing` });
  }


  get(): Observable<Organization[]> {
    return this.getOrganizations().pipe(
      tap(organizations => this.organizations = organizations)
    )
  }

  organizationInit(): Observable<OrganizationView> {
    return this._httpClient.get<any>(`${environment.apiConfig.baseUrl}/organizations/${this.organizationSelectedId}`).pipe(
      tap({
        error: (error: HttpErrorResponse) => {
          if (error instanceof HttpErrorResponse && (error.status === 404 || error.status === 403)) {
            this._selectedOrganization = null;
            localStorage.removeItem('organizationSelected');
            console.log(this.organizationSelectedId);
          }
        }
      }),
      map(organization => organization?.organization),

      map(organization => ({ ...organization, isOwner: organization.users?.at(0)?.id === organization.owner.id })),
      tap((organization) => this._selectedOrganization = organization)
    )
  }
  organizationView(organizationId: string): Observable<OrganizationView> {
    return this._httpClient.get<any>(`${environment.apiConfig.baseUrl}/organizations/${this.organizationSelectedId}`).pipe(
      map(organization => organization?.organization),
      map(organization => ({ ...organization, isOwner: organization.users?.at(0)?.id === organization.owner.id }))
    );
  }

  select(organizationId: string): void {
    localStorage.setItem('organizationSelected', organizationId);
    location.replace('');
  }

  add(name: string, description: string): Observable<{ id: string }> {
    return this._httpClient.post<any>(`${environment.apiConfig.baseUrl}/organizations`, { name, description }).pipe(
      tap(() => this.get())
    );
  }

  edit(id: string, data: { name: string, description?: string, industry?: number }): Observable<any> {
    return this._httpClient.patch<any>(`${environment.apiConfig.baseUrl}/organizations/${id}`, data);
  }

  remove(id: string): Observable<any> {
    return this._httpClient.delete<any>(`${environment.apiConfig.baseUrl}/organizations/${id}`);
  }

  categoriesList(type: 'job' | 'ticket' = 'job'): Observable<Array<OrganizationCategory>> {
    return this._httpClient.get<OrganizationCategoriesList>(`${environment.apiConfig.baseUrl}/organizations/${this.organizationSelectedId}/categories/${type}`).pipe(
      map(catigoriesList => catigoriesList.list)
    );
  }

  addCategory(data: OrganizationCategory, type: string = 'job'): Observable<any> {
    return this._httpClient.post<any>(`${environment.apiConfig.baseUrl}/organizations/${this.organizationSelectedId}/categories/${type}`, data);
  }

  updateCategory(data: OrganizationCategory, type: string = 'job'): Observable<any> {
    return this._httpClient.patch<any>(`${environment.apiConfig.baseUrl}/organizations/${this.organizationSelectedId}/categories/${type}/${data.id}`, { name: data.name });
  }

  deleteCategory(id: string, type: string = 'job'): Observable<any> {
    return this._httpClient.delete<any>(`${environment.apiConfig.baseUrl}/organizations/${this.organizationSelectedId}/categories/${type}/${id}`, {});
  }

  viewUser(id: string): Observable<User> {
    return this._httpClient.get<{ user: User }>(`${environment.apiConfig.baseUrl}/organizations/${this.organizationSelectedId}/users/${id}`).pipe(
      map(user => user.user)
    );
  }

  getTickets(isClosed: boolean = false, userId: string = undefined) {
    let params = new HttpParams();
    // const userId = this.userService.userId;
    params = params.append('status', 'Open');
    // params = params.append('userId', userId);
    if (isClosed) {
      params = params.append('status', 'Closed');
    }
    if (userId) {
      params = params.append('userId', userId);
    }
    return this._httpClient.get<Tickets>(`${environment.apiConfig.baseUrl}/organizations/${this.organizationSelectedId}/tickets`, { params }).pipe(
      map(tasks => tasks.list),
      map(tickets => tickets.sort((t1, t2) => t2.rank - t1.rank))
    );
  }

  completeOnboard(organizationId: string, data: { completedSteps: Array<string>, completed: boolean, skipped: boolean }): Observable<unknown> {
    return this._httpClient.put<any>(`${environment.apiConfig.baseUrl}/organizations/${organizationId}/onboarding`, data);
  }


  userRemove(userId: string) {
    return this._httpClient.delete<any>(`${environment.apiConfig.baseUrl}/organizations/${this.organizationSelectedId}/users/${userId}`, {});
  }

  auditLogs(params: HttpParams) {
    return this._httpClient.get<AuditLogs>(`${environment.apiConfig.baseUrl}/organizations/${this.organizationSelectedId}/audit/logs`, { params });
  }

  clients(): Observable<Array<Clients>> {
    return this._httpClient.get<any>(`${environment.apiConfig.baseUrl}/organizations/${this.organizationSelectedId}/clients`).pipe(
      map(clients => clients.list || [])
    );
  }

}