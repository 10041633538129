<div class="flex flex-col gap-4" [formGroup]="sessionForm">
  <div class="flex justify-between items-center">
    <div class="text-2xl">
      <span *ngIf="data.type === 'add'">Add Time</span>
      <span *ngIf="data.type === 'edit'">Edit Time</span>
    </div>
    <button mat-icon-button class="ml-auto" matDialogClose>
      <mat-icon svgIcon="mat_solid:close"></mat-icon>
    </button>
  </div>
  <mat-form-field class="w-full fuse-mat-dense" subscriptSizing="dynamic">
    <mat-label>Project or Task</mat-label>
    <mat-select formControlName="object" [compareWith]="compare">
      <mat-optgroup [label]="objects.key" *ngFor="let objects of objects$ | async | keyvalue">
        <mat-option [value]="{id: object.id, objectKind: object.objectKind}" *ngFor="let object of objects.value">{{object.title}}</mat-option>
      </mat-optgroup>
    </mat-select>
    <mat-error>Select Project or Task!</mat-error>
  </mat-form-field>
  <div class="flex flex-col md:flex-row items-center justify-between gap-2">
    <mat-form-field class="w-full fuse-mat-dense" subscriptSizing="dynamic">
      <mat-label>Start Time</mat-label>
      <input matInput [ngxMatDatetimePicker]="pickerStart" placeholder="Choose a date" formControlName="start" [max]="maxStartTime" readonly>
      <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
      <ngx-mat-datetime-picker #pickerStart [showSpinners]="true" [showSeconds]="false">
      </ngx-mat-datetime-picker>
      <mat-error>Start Time error!</mat-error>
    </mat-form-field>
    <mat-form-field class="w-full fuse-mat-dense" subscriptSizing="dynamic">
      <mat-label>End Time</mat-label>
      <input matInput [ngxMatDatetimePicker]="pickerEnd" placeholder="Choose a date" formControlName="end" [min]="minEndTime" readonly>
      <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
      <ngx-mat-datetime-picker #pickerEnd [showSpinners]="true" [showSeconds]="false">
      </ngx-mat-datetime-picker>
      <mat-error>End Time error!</mat-error>
    </mat-form-field>
  </div>
  <span class="text-secondary text-lg font-semibold">
    <span>Duration:</span>
    {{duration | secondsToTimePipe}}
  </span>
  <div class="flex flex-col" mat-dialog-actions>
    <div class="flex gap-2 ml-auto">
      <button mat-flat-button matDialogClose>Cancel</button>
      <button mat-raised-button color="primary" (click)="submit()" [disabled]="(submitting$ | async) || sessionFormInvalid">Submit</button>
    </div>
  </div>
</div>