import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { FuseFullscreenModule } from '@fuse/components/fullscreen';
import { FuseLoadingBarModule } from '@fuse/components/loading-bar';
import { FuseNavigationModule } from '@fuse/components/navigation';
import { LanguagesModule } from 'app/layout/common/languages/languages.module';
import { MessagesModule } from 'app/layout/common/messages/messages.module';
import { NotificationsModule } from 'app/layout/common/notifications/notifications.module';
import { QuickChatModule } from 'app/layout/common/quick-chat/quick-chat.module';
import { SearchModule } from 'app/layout/common/search/search.module';
import { ShortcutsModule } from 'app/layout/common/shortcuts/shortcuts.module';
import { UserModule } from 'app/layout/common/user/user.module';
import { SharedModule } from 'app/shared/shared.module';
import { DenseLayoutComponent } from 'app/layout/layouts/vertical/dense/dense.component';
import { OrganizationComponent } from '../../../common/organization/organization.component';
import { SchemeSwitcherComponent } from '../../../common/scheme-switcher/scheme-switcher.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AdminAppComponent } from 'app/layout/common/admin-app/admin-app.component';
import { TimeTrackingWidgetComponent } from 'app/shared/components/time-tracking-widget/time-tracking-widget.component';
import { TimeTrackingWidget3Component } from 'app/shared/components/time-tracking-widget3/time-tracking-widget3.component';
import { SessionAddComponent } from 'app/modules/user/modules/work-history/components/session-add/session-add.component';
@NgModule({
  declarations: [
    DenseLayoutComponent,
    OrganizationComponent,
    SchemeSwitcherComponent,
    AdminAppComponent
  ],
  imports: [
    HttpClientModule,
    RouterModule,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatMenuModule,
    FuseFullscreenModule,
    FuseLoadingBarModule,
    FuseNavigationModule,
    LanguagesModule,
    MessagesModule,
    NotificationsModule,
    QuickChatModule,
    SearchModule,
    ShortcutsModule,
    UserModule,
    SharedModule,
    MatTooltipModule,
    TimeTrackingWidgetComponent,
    TimeTrackingWidget3Component,
    SessionAddComponent
  ],
  exports: [
    DenseLayoutComponent
  ]
})
export class DenseLayoutModule {
}
