import { Component, OnDestroy, ChangeDetectionStrategy, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ProjectService } from 'app/services/project.service';
import { Subject, takeUntil, BehaviorSubject, map } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from 'app/services/user.service';
import { ProjectStatus } from 'app/models/project/project';

@Component({
  selector: 'tm-add-task',
  templateUrl: './add-task.component.html',
  styleUrls: ['./add-task.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddTaskComponent implements OnDestroy {
  readonly projects$ = this.projectService.projects$.pipe(
    map(projects => projects.filter(pr => pr.status === ProjectStatus.Normal))
  );
  private readonly destroy$ = new Subject<void>();
  submitting$ = new BehaviorSubject<boolean>(false);
  readonly taskForm: FormGroup = this.fb.group({
    project: [null, [Validators.required]],
    taskName: ['', [Validators.required]],
    taskDescription: null,
    assigneeId: null,
  });

  constructor(
    private readonly userService: UserService,
    private readonly dialogRef: MatDialogRef<AddTaskComponent>,
    @Inject(MAT_DIALOG_DATA) private readonly projectId: string,
    private readonly fb: FormBuilder,
    private readonly projectService: ProjectService) {
    this.taskForm.get('project').setValue(projectId);
  }

  get formInValid(): boolean {
    return this.taskForm.invalid;
  }

  submit(): void {
    const projectId = this.taskForm.get('project').value;
    this.submitting$.next(true);
    const data = {
      title: this.taskForm.get('taskName').value,
      description: this.taskForm.get('taskDescription').value,
    }
    this.projectService.createTicket(projectId, data).pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: () => this.dialogRef.close('success'),
      error: () => this.submitting$.next(false)
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.submitting$.complete();
  }
}
