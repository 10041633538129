import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { InviteFormComponent } from 'app/modules/invite/components/invite-form/invite-form.component';
import { Observable, Subject, filter, map, takeUntil, BehaviorSubject, tap, merge, delay, shareReplay, switchMap } from 'rxjs';
import { UserService } from 'app/services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Project } from 'app/models/project/project';
import { OrganizationService } from 'app/services/organization.service';
import { ProjectEditComponent } from 'app/modules/organization/modules/projects/components/project-edit/project-edit.component';
import { AddNewTaskComponent } from 'app/modules/organization/modules/tasks/components/add-new-task/add-new-task.component';
import { AddNewProjectComponent } from 'app/modules/organization/modules/projects/components/add-new-project/add-new-project.component';


@Component({
  selector: 'tm-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OnboardingComponent implements OnInit, OnDestroy {
  @ViewChild('infoProject', { static: true }) infoProject: TemplateRef<any>;
  private createdProjectId = null;
  readonly isOrganizationOwner$ = this.userService.isOrganizationOwner$.pipe(shareReplay(1));
  readonly isCreateProject$ = new BehaviorSubject<boolean>(false);
  readonly isInviteUsers$ = new BehaviorSubject<boolean>(false);
  readonly isCreateTask$ = new BehaviorSubject<boolean>(false);
  readonly isDownloadApp$ = new BehaviorSubject<boolean>(false);
  private readonly destroy$ = new Subject<void>();
  readonly submitting$ = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly dialog: MatDialog,
    private readonly router: Router,
    private readonly organizationService: OrganizationService,
    private readonly userService: UserService,
    private snackBar: MatSnackBar,) { }

  get progress$(): Observable<number> {
    return merge(this.isCreateProject$, this.isInviteUsers$, this.isCreateTask$, this.isDownloadApp$).pipe(
      map(() => [this.isCreateProject$.value, this.isInviteUsers$.value, this.isCreateTask$.value, this.isDownloadApp$.value]),
      map(stepsCompleted => stepsCompleted.filter(stepCompleted => stepCompleted)?.length),
      map(stepsCompletedCount => stepsCompletedCount * 25)
    )
  }

  createProject(): void {
    (this.dialog.open(AddNewProjectComponent, { data: { data: null, type: 'add' } }).afterClosed() as Observable<Project | undefined>).pipe(
      filter((project => project?.id !== undefined)),
      tap(project => {
        this.createdProjectId = project
      }),
      takeUntil(this.destroy$),
    ).subscribe(() => {
      this.isCreateProject$.next(true);
    });
  }

  inviteUsers(): void {
    if (this.createdProjectId) {
      const dialog = this.dialog.open(InviteFormComponent).addPanelClass(['min-w-80', 'max-w-full', 'w-full', 'h-full', 'sm:h-fit', 'sm:w-100', 'md:w-160']).afterClosed();
      dialog.pipe(
        filter(isUpadate => isUpadate)
      ).subscribe(() => {
        this.isInviteUsers$.next(true);
      });
    } else {
      this.snackBar.openFromTemplate(this.infoProject, { duration: 4000 });
    }
  }

  addTask(): void {
    if (this.createdProjectId) {
      const dialog = this.dialog.open(AddNewTaskComponent, { data: this.createdProjectId }).addPanelClass(['']).afterClosed();
      dialog.pipe(
        filter(isUpadate => isUpadate)
      ).subscribe(() => {
        this.isCreateTask$.next(true);
      });
    } else {
      this.snackBar.openFromTemplate(this.infoProject, { duration: 4000 });
    }
  }

  downloadApp(): void {
    this.isDownloadApp$.next(true);
    window.open('https://install.appcenter.ms/orgs/spectrotime/apps/spectrotime/distribution_groups/prod', "_blank");
  }

  complete(): void {
    this.isCreateProject$.next(true);
    this.isInviteUsers$.next(true);
    this.isDownloadApp$.next(true);
    this.isCreateTask$.next(true);
  }

  ngOnInit(): void {
    this.progress$.pipe(
      takeUntil(this.destroy$),
      filter(progress => progress === 100),
      switchMap(() => {
        this.submitting$.next(true);
        const organizationId = this.organizationService.organizationSelectedId;
        const data = {
          skipped: false,
          completed: true,
          completedSteps: [],
        }
        return this.organizationService.completeOnboard(organizationId, data);
      })
    ).subscribe({
      next: () => this.router.navigateByUrl('organization/dashboard'),
      error: () => this.submitting$.next(true)
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
