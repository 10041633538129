import { Route } from '@angular/router';
import { LayoutComponent } from 'app/layout/layout.component';
import { LoginFailedComponent } from './shared/pages/login-failed/login-failed.component';
import { ReportIssueFormComponent } from './modules/app-service/components/report-issue-form/report-issue-form.component';
import { RegistrationCompleteComponent as RegistrationOrganizationCompleteComponent } from './modules/organization/modules/settings/pages/registration-complete/registration-complete.component';
import { OnboardingComponent } from './shared/pages/onboarding/onboarding.component';
import { OnboardingGuard } from './shared/pages/onboarding/onboarding.guard';
import { RegistrationCompleteComponent as RegistrationUserCompleteComponent } from './modules/user/pages/registration-complete/registration-complete.component';
import { AccessDeniedComponent } from './shared/pages/access-denied/access-denied.component';
import { RegistrationCompletedGuard } from './modules/user/registration-completed.guard';
import { SignUpComponent } from './shared/pages/sign-up/sign-up.component';
import { RegistrationCompleteGuard } from './modules/organization/modules/settings/registration-complete.guard';
import { SignOutComponent } from './shared/pages/sign-out/sign-out.component';
import { AuthGuard } from '@auth0/auth0-angular';
import { inject } from '@angular/core';
import { OrganizationGuardService } from './services/organization-guard.service';
import { UserService } from './services/user.service';

export const appRoutes: Route[] = [
  {
    path: 'login-failed',
    component: LoginFailedComponent,
    title: 'Login Failed'
  },
  {
    path: 'signout',
    component: SignOutComponent,
    title: 'Sign Out'
  },
  // {
  //   path: 'subscribtions',
  //   component: SubscribtionsComponent,
  // },
  {
    path: 'signup',
    component: SignUpComponent,
    title: 'Sign Up'
  },
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'company/complete-registration',
        component: RegistrationOrganizationCompleteComponent,
        canActivate: [RegistrationCompleteGuard]
      },
      {
        path: 'onboarding',
        component: OnboardingComponent,
        canDeactivate: [OnboardingGuard],
        canActivate: [OnboardingGuard],
      },
      {
        path: 'user/complete-registration',
        component: RegistrationUserCompleteComponent,
        canActivate: [RegistrationCompletedGuard],
        canDeactivate: [RegistrationCompletedGuard],
      },
      {
        path: '',
        component: LayoutComponent,
        canActivate: [/*AppGuard*/() => inject(OrganizationGuardService).guard$],
        // runGuardsAndResolvers: 'always',
        // runGuardsAndResolvers: (curr: ActivatedRouteSnapshot, future: ActivatedRouteSnapshot) => {
        //   console.log(curr, future)
        //   return false
        // },
        children: [
          {
            path: 'report-issue-form',
            component: ReportIssueFormComponent
          },
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'tasks',
          },
          {
            path: 'user', loadChildren: () => import('app/modules/user/user.module').then(m => m.UserModule),
          },
          {
            path: 'organization',
            loadChildren: () => import('app/modules/organization/organization.module').then(m => m.OrganizationModule),
            canActivate: [() => inject(UserService).isOrganizationOwnerGuard$]
          },
          {
            path: 'settings', loadChildren: () => import('app/modules/settings/settings.routes').then(r => r.ROUTES),
          },
          {
            path: 'screenshots', loadChildren: () => import('app/modules/screenshots/screenshots.routing').then(r => r.ROUTES)
          },
          {
            path: 'tasks', loadChildren: () => import('app/modules/task/tasks.routing').then(r => r.ROUTES)
          },
          // {
          //   path: '', loadChildren: () => import('app/modules/roles-assign/roles-assign.module').then(m => m.RolesAssignModule)
          // },
        ]
      },
    ]
  },
  {
    path: 'access-denied',
    component: AccessDeniedComponent,
  },
  // 404 & Catch all
  {
    path: '', loadChildren: () => import('app/modules/errors/errors.module').then(m => m.ErrorsModule)
  },
  {
    path: '**',
    redirectTo: 'errors/404-not-found'
  },
];
