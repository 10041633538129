import { OrganizationUser } from "../user/user"

export interface OrganizationView {
  isOwner?: boolean,
  createdAt: string,
  description: null | string,
  id: string
  name: string
  industry?: number,
  onboarding?: {
    completedSteps: Array<string>,
    completed: boolean,
    skipped: boolean
  },
  owner: OrganizationOwner,
  users: Array<OrganizationUser>,
  projects?: Array<any>,
  subscription: { id: string } | null
}

export interface Organization {
  createdAt: string,
  description: null | string,
  id: string
  name: string
  owner: {
    id: string,
    displayName: string,
    role: OrganizationOwner
  },
  industry?: number
}

export enum UserOrganizationRole {
  Owner = 'Owner',
  Viewer = 'Viewer',
  Member = 'Member',
}

export interface OrganizationOwner {
  id: string,
  displayName: string,
  role: UserOrganizationRole
}