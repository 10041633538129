import { inject, Injectable } from '@angular/core';
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { environment } from 'environments/environment';
import { BehaviorSubject, EMPTY, Observable, ReplaySubject, Subject, Subscriber, catchError, defer, delay, filter, from, lastValueFrom, map, merge, of, retry, shareReplay, skip, switchMap, take, takeUntil, tap, timer } from 'rxjs';
import { OrganizationService } from './organization.service';
import { AuthService } from '@auth0/auth0-angular';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  private readonly _reconnected$ = new Subject<void>();
  private readonly _reconnect$ = new Subject<void>();
  private hubConnection: signalR.HubConnection = this.createHubConnection();
  private readonly _isConnect$ = new ReplaySubject<boolean>(1);
  private readonly _error$ = new Subject<void>();
  private readonly auth0Service = inject(AuthService);
  private readonly organizationService = inject(OrganizationService);
  private readonly onEvents$ = new Subject<{ methodName: string, args: Array<any> }>();
  private readonly onEvents = new Set<string>();

  constructor() {
    console.log('SignalRService init');
    this.connect().subscribe();
  }

  readonly isConnected$ = this._isConnect$.asObservable().pipe(
    shareReplay({ bufferSize: 1, refCount: true })
  );
  readonly isReconnected$ = this._reconnected$.asObservable().pipe(skip(1));

  readonly onlineChanged$ = this.on('UserOnlineChanged').pipe(
    map(args => {
      const [id, online] = args;
      return { id, online };
    })
  );
  // readonly onlineChanged$ = this.isConnected$.pipe(
  //   switchMap(() => defer(() => new Observable<{ id: string, online: boolean }>((observer) => this.hubConnection.on('UserOnlineChanged', (id, online) => observer.next({ id, online }))))),
  //   retry({ delay: () => this.isReconnected$ }),
  //   shareReplay({ bufferSize: 1, refCount: true }),
  //   catchError(() => of(null))
  // );

  private reconnect() {
    this._reconnect$.next();
  }

  private createHubConnection() {
    return new HubConnectionBuilder()//.configureLogging(LogLevel.Trace)
      .withUrl(`${environment.hubConnection}`, {
        accessTokenFactory: () => lastValueFrom(this.auth0Service.getAccessTokenSilently().pipe(take(1)))
      }).withAutomaticReconnect([2, 4, 20]).build();
  }

  private connectionErrorHandler(err?: any) {
    console.error('Error while starting connection: ' + err);
    this._error$.next();
    this._isConnect$.next(false);
    this.reconnect();
  }

  public connect() {
    return merge(
      this._reconnect$.pipe(delay(7500), tap(() => console.log('rec'))),
      this.auth0Service.isAuthenticated$.pipe(
        filter(isAuthenticated => !!isAuthenticated)
      )
    ).pipe(
      switchMap(() => {
        return from(this.hubConnection.start()).pipe(
          tap({
            next: () => {
              console.log('SignalR connected');
              this._isConnect$.next(true);
              this.hubConnection.onclose(() => {
                this.hubConnection = this.createHubConnection();
                console.log('SignalR close');
                this.connectionErrorHandler();
              });
            },
            error: (err) => this.connectionErrorHandler(err)
          }),
          retry({ delay: () => this._reconnect$ })
        )
      }),
      switchMap(() => from(this.hubConnection.invoke('Subscribe', this.organizationService.organizationSelectedId)).pipe(
        takeUntil(this._error$),
        tap({
          next: () => {
            console.log('Subscribe');
            this._reconnected$.next();
          },
          error: (err) => console.error('Subscribe invoke error')
        }),
        retry({
          count: 9,
          delay: (_, count) => timer(Math.pow(2, count) * 1000)
        }),
        catchError(() => {
          console.error('Subscribe error!');
          return EMPTY;
        })
      ))
    );
  }

  on(methodName: string) {
    if (!this.onEvents.has(methodName)) {
      this.onEvents.add(methodName);
      this.hubConnection.on(methodName, (...args) => this.onEvents$.next({ methodName, args: [...args] }));
    }
    return this.isConnected$.pipe(
      filter(isConnect => isConnect),
      switchMap(() => this.onEvents$.asObservable()),
      filter((event) => event.methodName === methodName),
      map((event) => event.args),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  invoke(methodName: string, ...args: any[]) {
    return this.hubConnection?.invoke(methodName, ...args);
  }
}