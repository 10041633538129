import { inject, Injectable, NgZone } from '@angular/core';
import { filter, lastValueFrom, map, ReplaySubject, shareReplay, skip, Subject, switchMap, take, tap } from 'rxjs';
import { OrganizationService } from './organization.service';
import { AuthService } from '@auth0/auth0-angular';

@Injectable({
  providedIn: 'root'
})
export class SignalRSWService {
  private sharedWorker!: SharedWorker;
  private readonly _reconnected$ = new Subject<void>();
  private readonly _isConnect$ = new ReplaySubject<boolean>(1);
  private readonly _error$ = new Subject<void>();
  private readonly auth0Service = inject(AuthService);
  private readonly organizationService = inject(OrganizationService);
  private readonly zone = inject(NgZone);
  private readonly invokeEvents$ = new Subject<string>();
  private readonly onEvents$ = new Subject<{ methodName: string, args: Array<any> }>();
  readonly isConnected$ = this._isConnect$.asObservable().pipe(
    shareReplay({ bufferSize: 1, refCount: true })
  );
  readonly isReconnected$ = this._reconnected$.asObservable().pipe(skip(1));
  readonly onlineChanged$ = this.on('UserOnlineChanged').pipe(
    map(args => {
      const [id, online] = args;
      return { id, online };
    })
  );

  constructor() {
    console.log('SignalRSharedWorkerService init');
    this.initializeSharedWorker().subscribe(() => this.connect());
  }

  private initializeSharedWorker() {
    if (typeof SharedWorker === 'undefined') {
      console.error('SharedWorker is not supported in this environment');
      return;
    }
    this.sharedWorker = new SharedWorker(new URL('/src/app/shared.worker', import.meta.url), { name: 'TimeShared', type: 'module' }); // this is the path of our shared-worker.js file.
    this.sharedWorker.port.start();
    this.sharedWorker.port.onmessage = (event) => {
      const { command, error, methodName, args } = event.data;
      this.zone.run(() => {
        switch (command) {
          case 'connected':
            this._isConnect$.next(true);
            break;
          case 'connectionError':
            this.connectionErrorHandler(error);
            break;
          case 'invoke':
            this.invokeEvents$.next('invoke');
            break;
          case 'on':
            this.onEvents$.next({ methodName, args });
            break;
          case 'reconnected':
            this._reconnected$.next();
            console.log('reconected')
            break;
        }
      });
    };
    return this.auth0Service.isAuthenticated$.pipe(
      filter(isAuthenticated => !!isAuthenticated)
    )
  }

  async connect() {
    const data = {
      orgId: this.organizationService.organizationSelectedId,
      token: await lastValueFrom(this.auth0Service.getAccessTokenSilently().pipe(take(1)))
    };
    this.sharedWorker.port.postMessage({ command: 'connect', data });
  }


  on(methodName: string) {
    const data = {
      methodName,
    };
    return this.isConnected$.pipe(
      filter(isConnect => isConnect),
      tap(() => this.sharedWorker.port.postMessage({ command: 'on', data })),
      switchMap(() => this.onEvents$.asObservable()),
      filter((event) => event.methodName === methodName),
      map((event) => event.args),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  invoke(methodName: string, args: any[]) {
    const data = {
      methodName,
      args
    };
    console.log(data)
    this.sharedWorker.port.postMessage({ command: 'invoke', data });
    return lastValueFrom(this.invokeEvents$);
  }

  private connectionErrorHandler(err?: any) {
    console.error('Error while starting connection: ' + err);
    this._error$.next();
    this._isConnect$.next(false);
  }
}