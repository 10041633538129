import { ChangeDetectionStrategy, Component, inject, Inject, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { ClientSession } from 'app/models/client/client-state';
import { BehaviorSubject, interval, map, Observable, shareReplay, startWith, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { DateTime } from 'luxon';
import { TimeTrackingService } from 'app/services/time-tracking.service';
import { ObjectKind, ObjectSession } from 'app/services/objects-session';
import { MatRadioChange, MatRadioModule } from '@angular/material/radio';
import { MatDividerModule } from '@angular/material/divider';
import { ClientService } from 'app/services/client.service';

export function secondsToTime(minutes: number): string {
  if (minutes >= 60) {
    const isHourDuration = Math.floor(minutes / 60);
    const h = isHourDuration.toString();
    const m = Math.floor(minutes % 60);
    return `${h} hour(s) ${m > 0 ? m.toFixed(0) + ' minute(s)' : ''}`;
  } else return `${minutes.toFixed(0)} minute(s)`;
}

@Component({
  selector: 'tm-idle-time',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatDialogModule, MatButtonModule, MatRadioModule, MatDividerModule],
  templateUrl: './idle-time.component.html',
  styleUrls: ['./idle-time.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IdleTimeComponent implements OnInit, OnDestroy {
  readonly isResume$ = new BehaviorSubject<boolean>(false);
  private readonly destroy$ = new Subject<void>();
  readonly error$ = new Subject<string>();
  static MIDNIGHT = { hour: 0, minute: 0, second: 0, millisecond: 0 };
  private isSaveWorkingTime = false;
  readonly dialogRef = inject(MatDialogRef<IdleTimeComponent>);
  private readonly _duration$ = interval(60000).pipe(
    startWith(""),
    map(() => DateTime.now()),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  get duration$() {
    return this._duration$.pipe(
      map((currentDate) => +(currentDate.diff(DateTime.fromISO(this.data.committedAt), 'minutes')).minutes),
      map(minutesCount => secondsToTime(minutesCount)),
    )
  }

  get range$(): Observable<string> {
    return this._duration$.pipe(
      map((currentDate) => {
        const committedDate = DateTime.fromISO(this.data.committedAt).set(IdleTimeComponent.MIDNIGHT);
        const currDate = currentDate.set(IdleTimeComponent.MIDNIGHT);
        if (committedDate === currDate) {
          return `${DateTime.fromISO(this.data.committedAt).toFormat('HH:mm')} - ${currentDate.toFormat('HH:mm')}`
        } else return `${DateTime.fromISO(this.data.committedAt).toFormat('dd.MM HH:mm')} - ${currentDate.toFormat('dd.MM HH:mm')}`
      })
    )
  }

  get selectedObject() {
    const objectKind = this.data.session.objectKind;
    const objectId = this.data.session.objectId;
    const object = this.data.objectsList.find(object => object.id === objectId && object.objectKind === objectKind)
    return object;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) readonly data: { session: ClientSession, committedAt: string, objectsList: Array<ObjectSession> },
    private readonly timeTrackingService: TimeTrackingService,
    private readonly clientService: ClientService) { }

  isWorkingTimeChange({ value }: MatRadioChange) {
    this.isSaveWorkingTime = value;
  }

  stop() {
    const date = (this.isSaveWorkingTime) ? DateTime.now().toUTC().toISO() : this.data.committedAt;
    this.timeTrackingService.stop(date).subscribe({
      next: () => { this.dialogRef.close() },
      error: (err) => { this.error$.next(err) }
    });
  }

  private play(objectId: string, objectKind: ObjectKind) {
    const currDate = DateTime.now().toUTC().toISO();
    return this.timeTrackingService.play(objectId, objectKind, currDate);
  }

  private commit(id: string) {
    const committedAt = DateTime.now().toUTC().toISO();
    return this.timeTrackingService.commitSession(id, committedAt).then(
      () => {
        // this.clientService.refreshState$.next();
        // const timeWorked = DateTime.now().toUTC().diff(DateTime.fromISO(startedAt), 'seconds').seconds;
        // this.timeTrackingService.init(timeWorked, committedAt);
        // this.timeTrackingService.run(id, committedAt, startedAt);
      }).catch((err) => {
        this.error$.next(err);
        this.isResume$.next(false);
      });
  }

  continue() {
    this.isResume$.next(true);
    const { session: { objectId, objectKind, id }, committedAt } = this.data;
    if (this.isSaveWorkingTime) {
      this.commit(id);
    } else {
      this.timeTrackingService.stop(committedAt).pipe(
        switchMap(() => this.play(objectId, objectKind))
      ).subscribe({
        next: () => { this.dialogRef.close() },
        error: (err) => { this.error$.next(err) }
      })
    }
  }

  ngOnInit(): void {
    this.timeTrackingService.clientSessionResumed$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.clientService.refreshState$.next();
      this.dialogRef.close();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
