<div class="flex flex-col gap-4">
  <div class="flex items-center justify-between">
    <div class="text-2xl">Add task</div>
    <button mat-icon-button matDialogClose><mat-icon svgIcon="mat_solid:close"></mat-icon></button>
  </div>
  <div [formGroup]="taskForm" class="flex flex-col gap-2">
    <mat-form-field [subscriptSizing]="'dynamic'" class="fuse-mat-dense w-full">
      <mat-label>Select a Project</mat-label>
      <mat-select formControlName="project">
        <mat-option *ngFor="let project of projects$ | async" [value]="project.id">{{project.name}}</mat-option>
      </mat-select>
      <mat-error>Project is required!</mat-error>
    </mat-form-field>
    <mat-form-field [subscriptSizing]="'dynamic'" class="fuse-mat-dense w-full">
      <mat-label>Name</mat-label>
      <input matInput formControlName="taskName" name="title">
      <mat-error>Name is required!</mat-error>
    </mat-form-field>
    <mat-form-field [subscriptSizing]="'dynamic'" class="fuse-mat-dense w-full">
      <mat-label>Description</mat-label>
      <textarea matInput formControlName="taskDescription" name="taskDescription"></textarea>
    </mat-form-field>
  </div>
  <div class="flex gap-2 ml-auto">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-flat-button [color]="'primary'" [disabled]="formInValid || (submitting$ | async)" (click)="submit()">
      <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
      <span class="ml-2 mr-1">Add Task</span>
    </button>
  </div>
</div>